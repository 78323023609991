import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValuesType } from 'utility-types'

const CUSTOM_ICONS = [
    ['arrow-left', '../assets/icons/arrow-left.svg'],
    ['arrow-right', '../assets/icons/arrow-right.svg'],
    ['arrow-up', '../assets/icons/arrow-up.svg'],
    ['arrow-down', '../assets/icons/arrow-down.svg'],
    ['chevron-left', '../assets/icons/chevron-left.svg'],
    ['chevron-right', '../assets/icons/chevron-right.svg'],
    ['chevron-up', '../assets/icons/chevron-up.svg'],
    ['chevron-down', '../assets/icons/chevron-down.svg'],
    ['company-filled', '../assets/icons/company-filled.svg'],
    ['drop-left', '../assets/icons/drop-left.svg'],
    ['drop-right', '../assets/icons/drop-right.svg'],
    ['drop-up', '../assets/icons/drop-up.svg'],
    ['drop-down', '../assets/icons/drop-down.svg'],
    ['clock-filled-past', '../assets/icons/clock-filled-past.svg'],
    ['clock-filled-future', '../assets/icons/clock-filled-future.svg'],
    ['filter-outline', '../assets/icons/filter-outline.svg'],
    ['filter-filled', '../assets/icons/filter-filled.svg'],
    ['skip-first', '../assets/icons/skip-first.svg'],
    ['skip-last', '../assets/icons/skip-last.svg'],
    ['plus', '../assets/icons/plus.svg'],
    ['minus', '../assets/icons/minus.svg'],
    ['refresh', '../assets/icons/refresh.svg'],
    ['reset', '../assets/icons/reset.svg'],
    ['flag-outline', '../assets/icons/flag-outline.svg'],
    ['flag-filled', '../assets/icons/flag-filled.svg'],
    ['bookmark-outline', '../assets/icons/bookmark-outline.svg'],
    ['bookmark-filled', '../assets/icons/bookmark-filled.svg'],
    ['visibility-on', '../assets/icons/visibility-on.svg'],
    ['visibility-off', '../assets/icons/visibility-off.svg'],
    ['Standard', '../assets/icons/wagon-standard.svg'],
    ['Tank', '../assets/icons/wagon-tank.svg'],
    ['Intermodal', '../assets/icons/wagon-intermodal.svg'],
    ['Modular', '../assets/icons/wagon-modular.svg'],
    ['user', '../assets/icons/user.svg'],
    ['add-user', '../assets/icons/add-user.svg'],
    ['group', '../assets/icons/group.svg'],
    ['play', '../assets/icons/play.svg'],
    ['pause', '../assets/icons/pause.svg'],
    ['play-background', '../assets/icons/play-background.svg'],
    ['cloud', '../assets/icons/cloud.svg'],
    ['cloud-upload', '../assets/icons/cloud-upload.svg'],
    ['clock-outline', '../assets/icons/clock-outline.svg'],
    ['clock-filled', '../assets/icons/clock-filled.svg'],
    ['check', '../assets/icons/check-stroked.svg'],
    ['check-filled', '../assets/icons/check-filled.svg'],
    ['notification', '../assets/icons/notification.svg'],
    ['notification-off', '../assets/icons/notification-off.svg'],
    ['delete-outline', '../assets/icons/delete-outline.svg'],
    ['delete-filled', '../assets/icons/delete-filled.svg'],
    ['new-tab', '../assets/icons/new-tab.svg'],
    ['damage-report', '../assets/icons/wagon-damage-report.svg'],
    ['recommissions', '../assets/icons/wagon-recommissions.svg'],
    ['speech-bubble', '../assets/icons/speech-bubble.svg'],
    ['feedback', '../assets/icons/feedback.svg'],
    ['dialog', '../assets/icons/dialog.svg'],
    ['connect', '../assets/icons/connect.svg'],
    ['connect-issue', '../assets/icons/connect-issue.svg'],
    ['connect-none', '../assets/icons/connect-none.svg'],
    ['calendar-today', '../assets/icons/calendar-today.svg'],
    ['calendar-cancel', '../assets/icons/calendar-cancel.svg'],
    ['calendar-date', '../assets/icons/calendar-date.svg'],
    ['calendar-short-range', '../assets/icons/calendar-short-range.svg'],
    ['calendar-medium-range', '../assets/icons/calendar-medium-range.svg'],
    ['calendar-long-range', '../assets/icons/calendar-long-range.svg'],
    ['file', '../assets/icons/file.svg'],
    ['file-csv', '../assets/icons/file-csv.svg'],
    ['file-excel', '../assets/icons/file-excel.svg'],
    ['file-pdf', '../assets/icons/file-pdf.svg'],
    ['file-protocol-damage', '../assets/icons/file-protocol-damage.svg'],
    ['file-protocol-wib', '../assets/icons/file-protocol-wib.svg'],
    ['lightbulb', '../assets/icons/lightbulb.svg'],
    ['search', '../assets/icons/search.svg'],
    ['settings', '../assets/icons/settings.svg'],
    ['login', '../assets/icons/login.svg'],
    ['download', '../assets/icons/download.svg'],
    ['upload', '../assets/icons/upload.svg'],
    ['save', '../assets/icons/save.svg'],
    ['warning', '../assets/icons/warning.svg'],
    ['brake-shoe', '../assets/icons/brake-shoe.svg'],
    ['mileage', '../assets/icons/mileage.svg'],
    ['repair', '../assets/icons/repair.svg'],
    ['edit', '../assets/icons/edit.svg'],
    ['switch', '../assets/icons/switch.svg'],
    ['thumbs-up', '../assets/icons/thumbs-up.svg'],
    ['close', '../assets/icons/close.svg'],
    ['clear', '../assets/icons/clear.svg'],
    ['menu', '../assets/icons/menu.svg'],
    ['map-marker', '../assets/icons/map-marker.svg'],
    ['info', '../assets/icons/info.svg'],
    ['star', '../assets/icons/star.svg'],
    ['present', '../assets/icons/present.svg'],
    ['attention', '../assets/icons/attention.svg'],
    ['email', '../assets/icons/email.svg'],
    ['block', '../assets/icons/block.svg'],
    ['traigo-pin', '../assets/icons/traigo-pin.svg'],
    ['world', '../assets/icons/world.svg'],
    ['code', '../assets/icons/code.svg'],
    ['swiper', '../assets/icons/swiper.svg'],
    ['move', '../assets/icons/move.svg'],
    ['input', '../assets/icons/input.svg'],
    ['notifications', '../assets/icons/notifications.svg'],
    ['traigo-updates', '../assets/icons/traigo-updates.svg'],
    ['user-management', '../assets/icons/user-management.svg'],
    ['idle-times', '../assets/icons/idle-times.svg'],
    ['contract-management', '../assets/icons/contract-management.svg'],
    ['wagon-hire', '../assets/icons/wagon-hire.svg'],
    ['fleet-management', '../assets/icons/fleet-management.svg'],
    ['fast-track', '../assets/icons/fast-track.svg'],
    ['group-management', '../assets/icons/group-management.svg'],
    ['data-forwarding', '../assets/icons/data-forwarding.svg'],
    ['eta', '../assets/icons/eta.svg'],
    ['booking', '../assets/icons/booking.svg'],
    ['dispatch-management', '../assets/icons/dispatch-management.svg'],
    ['wagon-tracking', '../assets/icons/wagon-tracking.svg'],
    ['interest-fleetmanager', '../assets/icons/interests-fleetmanager.svg'],
    ['interest-purchaser', '../assets/icons/interests-purchaser.svg'],
    ['interest-scheduler', '../assets/icons/interests-scheduler.svg'],
    ['error-permission', '../assets/icons/error-permission.svg'],
    ['error-unauthorized', '../assets/icons/error-unauthorized.svg'],
    ['error', '../assets/icons/error.svg'],
    ['missing-chart-data', '../assets/icons/missing-chart-data.svg'],
    ['missing-data', '../assets/icons/missing-data.svg'],
    ['trailer', '../assets/icons/trailer.svg'],
    ['trailer-issue', '../assets/icons/trailer-issue.svg'],
    ['temperature', '../assets/icons/temperature.svg'],
    ['temperature-issue', '../assets/icons/temperature-issue.svg'],
    ['start-pin', '../assets/icons/start-pin.svg'],
    ['rating-very_bad-outline', '../assets/icons/rating-very_bad-outline.svg'],
    ['rating-poor-outline', '../assets/icons/rating-poor-outline.svg'],
    ['rating-medium-outline', '../assets/icons/rating-medium-outline.svg'],
    ['rating-good-outline', '../assets/icons/rating-good-outline.svg'],
    [
        'rating-excellent-outline',
        '../assets/icons/rating-excellent-outline.svg',
    ],
    ['rating-very_bad-filled', '../assets/icons/rating-very_bad-filled.svg'],
    ['rating-poor-filled', '../assets/icons/rating-poor-filled.svg'],
    ['rating-medium-filled', '../assets/icons/rating-medium-filled.svg'],
    ['rating-good-filled', '../assets/icons/rating-good-filled.svg'],
    ['rating-excellent-filled', '../assets/icons/rating-excellent-filled.svg'],
    ['geofence', '../assets/icons/geofence.svg'],
    ['geofence-flat', '../assets/icons/geofence-flat.svg'],
    ['geofence-outline', '../assets/icons/geofence-outline.svg'],
    ['enter-geofence', '../assets/icons/enter-geofence.svg'],
    ['exit-geofence', '../assets/icons/exit-geofence.svg'],
    ['operational-documents', '../assets/icons/operational-documents.svg'],
    ['powerbox-charging', '../assets/icons/powerbox-charging.svg'],
    ['powerbox-discharging', '../assets/icons/powerbox-discharging.svg'],
    ['powerbox-off', '../assets/icons/powerbox-off.svg'],
    ['group-objects', '../assets/icons/group-objects.svg'],
    ['lock-outline', '../assets/icons/lock-outline.svg'],
    ['unlock-outline', '../assets/icons/unlock-outline.svg'],
    ['lock-filled', '../assets/icons/lock-filled.svg'],
    ['unlock-filled', '../assets/icons/unlock-filled.svg'],
    ['empty-box', '../assets/icons/empty-box.svg'],
    ['returned-filled', '../assets/icons/returned-filled.svg'],
] as const

export const customIconKeys = CUSTOM_ICONS.map(([key]) => key)
export type AppIcon = ValuesType<typeof customIconKeys>

@Injectable({
    providedIn: 'root',
})
export class AppIconService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {}

    initCustomIcons() {
        for (const [name, path] of CUSTOM_ICONS) {
            this.matIconRegistry.addSvgIcon(
                name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(path)
            )
        }
    }
}
